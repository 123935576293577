import React from "react";
import { Container } from "react-bootstrap";

import "./about.less";

export const About = ({ data }) => {
  const practiceAboutTitle = data.practice_title.text;
  const practiceAboutDesc = data.practice_description.text;
  const practiceAboutBckImg = data.practice_background_img.url;

  return (
    <section className="practice-about-container">
      <article className="practice-about-wrapper">
        <img
          src={practiceAboutBckImg}
          alt=""
          className="practice-about-background"
        />
        <Container className="practice-about-deatils">
          <h2>{practiceAboutTitle}</h2>
          <p>{practiceAboutDesc}</p>
        </Container>
      </article>
    </section>
  );
};
