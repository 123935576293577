import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import get from "lodash/get";

import "./practice.less";

export default ({ data }) => {
  const practiceAreaData = useStaticQuery(graphql`
    {
      allPrismicPracticeAreas {
        nodes {
          data {
            practice_area_title {
              text
            }
            read_more_button_label {
              text
            }
          }
        }
      }
      allPrismicPracticeArea(sort: { order: ASC, fields: data___order }) {
        nodes {
          uid
          data {
            title
            sub_title {
              text
            }
            sub_heading{
              text
            }
            thumbnail_excerpt {
              text
              richText
            }
            mobile_thumbnail {
              url
            }
          }
        }
      }
    }
  `);

  const practiceAreas = get(
    practiceAreaData,
    "allPrismicPracticeArea.nodes",
    []
  );

  const buttonLabel = practiceAreaData.allPrismicPracticeAreas.nodes[0].data.read_more_button_label.text;

  // return null;

  return (
    <section className="practice-practice-areas">
      <article className="practice-practice-areas-wrapper">
        <Container>
          <h2>
            {
              practiceAreaData.allPrismicPracticeAreas.nodes[0].data
                .practice_area_title.text
            }
          </h2>
          <Row className="practices-container">
            {practiceAreas.map((p, idx) => {
              return (
                <Col xs={6} md={4} key={idx}>
                  <div className="single-practice">
                    <img src={p.data.mobile_thumbnail.url} alt="" />
                    <h4>{p.data.title}</h4>
                    <p>{p.data.thumbnail_excerpt.text}</p>
                    <Link to={`/practice-areas/${p.uid}`} className="btn yellow-hover">
                      {buttonLabel}
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </article>
    </section>
  );
};
