import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Hero from "../components/PracticeAreas/Hero/Hero";
import { About } from "../components/PracticeAreas/About/About";
import Practices from "../components/PracticeAreas/Practices/Practices";
import Contact from "../components/Contact/Contact";

export const PracticeAreas = (props) => {
  const { data } = props;
  const practiceData = data.allPrismicPracticeAreas.edges[0].node.data;
  return (
    <Layout>
      <Hero data={practiceData} />
      <About data={practiceData} />
      <Practices />
      <Contact map={true} />
    </Layout>
  );
};

export default PracticeAreas;

export const query = graphql`
  {
    allPrismicPracticeAreas {
      edges {
        node {
          data {
            practice_button_text {
              text
            }
            practice_hero_description {
              text
            }
            practice_hero_img {
              url
            }
            practice_hero_title {
              text
            }
            practice_background_img {
              url
            }
            practice_description {
              text
            }
            practice_title {
              text
            }
          }
        }
      }
    }
  }
`;
